'use strict';

angular.module('appApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('product', {
        url: '/product/:user/:id',
        templateUrl: 'app/product/product.html',
        controller: 'ProductCtrl',
        authenticate: 'user'
      });
  });
